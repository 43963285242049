import React from "react";

function AboutUs() {
    return (
        <div className="relative bg-[#FFFBEB] p-2 md:p-4 max-w-screen h-full">
            <div className="grid grid-cols-2 container justify-between mx-auto max-w-7xl">
                <div className="space-y-10 col-span-2 md:col-span-1 md:p-24">
                    <p className="flex-grow-0 flex-shrink-0 text-[32px] text-left text-[#fe5719]">
                        Empowering Affordable Remittance
                    </p>

                    <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-3">
                        <p className="flex-grow-0 flex-shrink-0 md:w-[513px] text-xl text-left text-[#2f4858]">
                            <span className="font-bold">How We Keep Costs Low:</span>
                            <br />
                            We consistently look for ways to keep transfer costs low, so that you
                            send as much of your hard-earned money as possible.
                            <br />
                            <br />
                            <span className="font-bold">Competitive Pricing:</span>
                            <br />
                            We're on average 46% cheaper than most banks.
                            <br />
                            <br />
                            <span className="font-bold">Transparent Fees:</span>
                            <br />
                            There are no hidden fees. You'll see all our fees upfront.
                            <br />
                            <br />
                            <span className="font-bold">Clear Exchange Rates:</span>
                            <br />
                            Our currency converter shows you the exchange rates, and once you select
                            your receive method and delivery partner, you'll see the total amount
                            your receiver will get.
                        </p>
                    </div>
                    <div className="flex  flex-grow-0 flex-shrink-0 relative gap-2">
                        <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-[#554adf]">
                            Our Security Policy
                        </p>
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M5.94 13.28L10.2867 8.93333C10.8 8.42 10.8 7.58 10.2867 7.06667L5.94 2.72"
                                stroke="#554ADF"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <div className="w-full col-span-2 md:col-span-1 h-auto bg-[#F4F4F4] rounded-xl">
                    <img
                        className="w-full h-auto md:p-5 rounded-tl-xl rounded-br-xl"
                        src="/images/rectangle-49.png"
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 container mt-3 md:mt-0   justify-between mx-auto max-w-7xl">
                <div className="w-full col-span-2 md:col-span-1 h-auto bg-[#F4F4F4] rounded-xl">
                    <img
                        className="w-full h-full md:p-5 rounded-tl-xl rounded-br-xl object-cover"
                        src="/images/image-3.png"
                    />
                </div>
                <div className="space-y-10 mt-3 md:p-24 col-span-2 md:col-span-1">
                    <p className="flex-grow-0 flex-shrink-0 text-[32px] text-left text-[#fe5719]">
                        Empowering Affordable Remittance
                    </p>
                    <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-3">
                        <p className="flex-grow-0 flex-shrink-0 md:w-[513px] text-xl text-left text-[#2f4858]">
                            <span className="font-bold">How We Offer Boundless Possibilities:</span>
                            <br />
                            We offer the best exchange rates for you to transfer funds to your loved
                            ones, manage payments for your small business, and oversee your global
                            finances. With Faremit, we provide you with boundless possibilities.
                        </p>
                    </div>
                    <div className="flex  flex-grow-0 flex-shrink-0 relative gap-2">
                        <p className="flex-grow-0 flex-shrink-0 text-lg text-left text-[#554adf]">
                            Our Security Policy
                        </p>
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M5.94 13.28L10.2867 8.93333C10.8 8.42 10.8 7.58 10.2867 7.06667L5.94 2.72"
                                stroke="#554ADF"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="absolute inset-0 flex justify-center items-center">
                <img
                    src="/images/gif_earth.gif"
                    className="w-25 h-25 object-cover"
                    alt="Earth Animation"
                />
            </div>
        </div>
    );
}

export default AboutUs;
